import React, { Component } from 'react';
import { graphql } from 'gatsby';
import AOS from 'aos';
import moment from 'moment-timezone';

import 'typeface-mali';
import styles from './event-page.module.css';
import Layout from '../components/layout/layout';
import Header from '../components/header/header';
import SEO from '../components/seo/seo';
import Footer from '../components/footer/footer';
import { parseRef } from '../services/local-storage';

class EventPageTemplate extends Component {
  componentDidMount() {
    this.aos = AOS;
    this.aos.init({
      once: true,
      duration: 1000,
    });

    parseRef(this.props.location);
  }

  componentDidUpdate() {
    this.aos.refresh();
  }

  render() {
    let { data } = this.props;

    const post = data.eventsJson;
    const archive = post.archive;
    const description = post.FullText.replace(/^(.{160}[^\s]*).*/, '$1');
    const startTime = post.Start;
    const series = post.Series;
    const momentTz = post.TimeZone;
    const imageUrl = post.Image;
    const eventKeys = post.eventKeys.map(item => {
      return `#${item} `;
    });
    return (
      <Layout>
        <SEO title={post.Subject} description={description} jsonLdString={post.jsonLdString} />
        <Header />
        <article className={styles.mainContainer}>
          <div
            className={styles.imageContainer}
            style={{
              backgroundImage: `url(${imageUrl})`,
            }}
          />
          <div className={styles.eventHeader}>
            <h1 className={styles.subject}>{post.Subject}</h1>
            {!archive ? (
              <div
                className={styles.rsvpButton}
                onClick={() => {
                  window.open('https://thelovemaze.com/events', '_blank');
                }}
              >
                TICKETS
              </div>
            ) : (
              <div className={styles.soldOutTag}>SOLD OUT</div>
            )}
          </div>
          {series ? (
            <div className={styles.startTime}>{series}</div>
          ) : (
            <time className={styles.startTime}>
              {moment(startTime, 'X')
                .tz(momentTz)
                .format('MMM D YYYY @ hh:mm A')}
            </time>
          )}
          <p className={styles.fullText}>{post.FullText}</p>

          <p className={styles.eventKeys}>{eventKeys}</p>
        </article>
        <Footer />
      </Layout>
    );
  }
}

export default EventPageTemplate;

export const query = graphql`
  query($slug: String!) {
    eventsJson(fields: { slug: { eq: $slug } }) {
      Subject
      FullText
      Start
      TimeZone
      Image
      Series
      Interest
      eventKeys
      RSVP
      archive
      LocationDetails {
        City
        State
        Zip
        long
        lat
      }
      jsonLdString
    }
  }
`;
